import {useState, useEffect} from 'react';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil} from '../recoil';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faLock, faXmark, faCheck} from '@fortawesome/pro-light-svg-icons';
import {faCircle} from '@fortawesome/pro-solid-svg-icons';
import variables from '../../styles/_variables.module.scss';
import Portal from '../portal';
import AccountDetails from './accountDetails';

export const Account = props => {
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const pageSize = useRecoilValue(pageSizeRecoil);

  const {data, loading} = useQuery(SUBSCRIPTIONS,
    {
      variables: {
        filters: {
          combineAnd: [
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      }
    }
  )
  const subscriptions = data?.Subscriptions?.result ?? [];

  // if this doesn't come back (fail case), it's safer to let them page the first 100*10 per
  let totalQueried = data?.Subscriptions?.metadata?.page?.records ?? totalRecordsState;
  
  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  let subStates = {
    N: {
      stage: 'Pending',
      icon: faXmark,
      color: variables.grayDark,
    },
    P: {
      stage: 'Active',
      icon: faLock,
      color: variables.privoroBlue
    },
    C: {
      stage: 'Completed',
      icon: faCheck,
      color: variables.privoroBlue
    },
    X: {
      stage: 'Canceled',
      icon: faXmark,
      color: variables.privoroRed 
    }
  };
  
  const iconFormatter = (cell) => {
    let state = subStates[cell];
    return(
      <div>
        <span className="fa-layers fa-fw fa-lg me-4">
          <FontAwesomeIcon icon={faCircle} size="2x" color={state.color} />
          <FontAwesomeIcon transform="shrink-1 right-6" icon={state.icon} color="white"/>
        </span>
        {state.stage}
      </div>
    );
  };
    
  const columns = [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'type',
      text: 'Subscription',
      formatter: (cell, row) => {
        if(cell === 'AS') {
          return 'Fulcrum';
        }
        return cell;
      }
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: iconFormatter
    },
    {
      dataField: 'start_time',
      text: 'Start Date (UTC)',
      formatter: dateFormatter
    },
    {
      dataField: 'end_time',
      text: 'Expiry Date (UTC)',
      formatter: dateFormatter
    }
  ];

  return (
    <>
      <Portal title="Subscription Details">
        <AccountDetails />
      </Portal>
      <ActionBar showHidden={false} showSearchBar={false} title="Subscriptions" />
      
      <Table
        columns={columns}
        data={subscriptions}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
      />
    </>
  );
};

const SUBSCRIPTIONS = gql`  
query getSubscriptions ($filters: GenericFilters) {
  Subscriptions (filters: $filters) {
    result {
      id
      type
      status
      start_time
      end_time
      created_at
      modified_at
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    }
  }
}
`;

export default Account;
