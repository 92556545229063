import React, {useState, createContext, useContext} from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { useQuery, gql } from "@apollo/client";

const PortalContext = createContext();

export const PortalContextProvider = (props) => {
  const origin = useLocation();
  const [portalOpen, setPortalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [defaultKey, setDefaultKey] = useState('0');

  // allow a click event to pass an identifier string for what was clicked
  // context tracks this so we know what do display in offcanvas
  const [clicked, setClicked] = useState('');
  const [originalRow, setOriginalRow] = useState(null);
  
  const {data: dData, loading: dLoading} = useQuery(DEVICES_BY_USERID, {
    variables: {
      filters: {
        combineAnd: [
          {field: "user_id", value: `${userId}` },
          {field: "with.product_type", value: "1"},
          {field: "with.device_configuration", value: "1"},
          {field: "with.hdm_config_preset_profile", value: "1"},
          {field: "with.firmware", value: "1"},
          {field: "with.reported_firmware", value: "1"},
          {field: "with.user", value: "1"},
          {field: "with.role", value: "1"},
          {field: "with.policy_group", value: "1"},
          {field: "with.policy", value: "1"},
          {field: "with.ota", value: "1"}
        ]
      }
    },
    skip: !userId
  });

  const {data: uData, loading: uLoading} = useQuery(USER_BY_ID, {
    variables: {
      filters: {
        combineAnd: [
          {field: "id", value: `${userId}`},
          {field: "with.role_groups", value: "1"},
          {field: "with.role", value: "1"}
        ]        
      }
    },
    skip: !userId
  });

  const device = Object.assign({}, dData?.Devices?.result?.[0]);
  const user = Object.assign({}, uData?.Users?.result?.[0]);
  device.user = user;
  let newData = null;
  
  // if we got nothing here, let it pass thru original row data (device usecase without user)
  if((Object.keys(device).length !== 0) && (Object.keys(user).length !== 0))
  {
    newData = Object.assign({}, device);
  }
  
  const togglePortal = (rowData) => {

    if(rowData?.type === 'click') {
      setDefaultKey(rowData?.target?.attributes?.defaultkey?.value.toString() ?? '0');
      setClicked(rowData?.target?.attributes?.clicked?.value ?? '');
    } else {
      if(rowData?.defaultKey == "") {
        setDefaultKey(rowData?.defaultKey)
      } else {
        setDefaultKey('0');
      }
      setClicked('');
    }

    switch(origin.pathname) {
      case '/users':
        setUserId(rowData?.id ?? null);
        break;
      case '/devices':
        setOriginalRow(rowData);
        setUserId(rowData?.user?.id ?? null);
        break;
      case '/policies':
        setOriginalRow(rowData);
        setUserId(null);
        break;
      default:
        setOriginalRow(rowData);
        setUserId(null);
        break;
    }
    setPortalOpen(!portalOpen);
  };

  return (
    <PortalContext.Provider value={{
      togglePortal: togglePortal,
      origin: origin,
      isShowing: portalOpen,
      rowData: originalRow ?? newData,
      defaultKey: defaultKey,
      clicked: clicked
      }}>
      {props.children}
    </PortalContext.Provider>
  );
};

PortalContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

/**
 * Device Configuration fragment
 *
 * device_configuration {
    id
    account_id
    is_anti_surveillance_enabled
    is_anti_surveillance_enabled_expiry
    is_hdm_enabled
    is_hdm_enabled_expiry
  }
 */

const DEVICES_BY_USERID = gql`
  query contextGetDevices($filters: GenericFilters!) {
    Devices (filters: $filters) {
      result {
        id
        policy_group_id
        policy_id
        device_model_id
        serial_number
        mcu_id
        is_active
        whitelist_ssid_list_id
        hdm_config_preset_profile_id
        hdm_config_preset_profile {
          name
        }
        device_configuration {
          id
          account_id
          is_anti_surveillance_enabled
          is_anti_surveillance_enabled_expiry
          is_hdm_enabled
          is_hdm_enabled_expiry
        }
        user {
          id
          username
          firstname
          lastname
          email
          phone
          is_active
          modified_at
        }
        policy {
          id
          name
          geofence_group_id
          description
          is_active
          is_delete
          is_phone_protected
          is_offline
          has_battery_warning
          battery_warning_threshold
          is_checked_in
          is_camera_exposed
          is_cellular_connected
          is_microphone_exposed
          is_phone_present
          policy_action_id
          created_at
          modified_at
          group {
            name
            id
          }
        }
        product_type_id
        product_type {
          id
          description
          samsung_family
          greyhound_family
          whippethusky_family
        }
        firmware {
          id
          version
          description
          is_active
        }
        ota_state
        ota_progress
        reported_firmware {
          version
        }
        modified_at
      }
      metadata {
        page {
          num
          len
          total
          inPage
        }
      }
    }
  }
`;

const USER_BY_ID = gql`
  query contextGetUserByID($filters: GenericFilters!){
    Users(filters: $filters){
      result {
        id
        username
        firstname
        lastname
        email
        phone
        is_active
        created_at
        modified_at
        role {
          id
          name
          description
          is_active
        }
      }
    }
  }
`;

export const usePortalContext = () => {
  return useContext(PortalContext);
};
