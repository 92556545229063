import FirmwareAssignmentModal from './firmwareAssignmentModal';
import Tooltip from '../tooltip';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faShieldKeyhole} from '@fortawesome/pro-solid-svg-icons';
import moment from 'moment';
import variables from '../../styles/_variables.module.scss';

export const fulcrumShield = (cell, row) => {
  let tmp = Number(cell);
  return shieldFormatter(tmp);
}

export const hdmShield = (cell, row) => {
  let tmp = Number(cell);
  
  if(
    row?.product_type?.samsung_family && 
    row?.device_configuration?.is_hdm_enabled_expiry > Math.floor(Date.now() / 1000)
  ) {
    return shieldFormatter(tmp);
  }

  return null;
}

const shieldFormatter = (time) => {
  let blue = time > moment().unix();
  
  return (
    <FontAwesomeIcon className="mx-auto" color={blue ? variables.privoroBlue : 'transparent'} size="lg" icon={faShieldKeyhole} />
  );

}

export const hdmPresetFormatter = (cell, row) => {
  let tmp = 'N/A';

  if(
    row?.product_type?.greyhound_family && 
    row?.device_configuration?.is_hdm_enabled_expiry > Math.floor(Date.now() / 1000) &&
    cell
  ) {
    if (cell == "0") {
      tmp = 'All On';
    } else {
      tmp = cell;
    }
  } 

  return tmp;
}

export const modelFormatter = (cell, row) => {
  // requirement is to only show tooltips (hover text) for NID/FID model labels
  let tooltipContent = '';
  if(cell?.includes('iPhone 12 FID')) {
    tooltipContent="iPhone 12 Face ID";
  }
  else if ( cell?.includes('iPhone 12 NID')) {
    tooltipContent="iPhone 12 non-Face ID";
  } 
  return (
    <>
    { tooltipContent !== '' ? <Tooltip  content={tooltipContent}>
      <span >{cell}</span>
    </Tooltip> : <span >{cell}</span>}
    </>
  );
}

export const firmwareAssignmentFormatter = (cell, row) => {
  return (
    <FirmwareAssignmentModal 
      cell={cell}
      row={row}
    />
  );
}
