import { gql, useMutation } from '@apollo/client';

const EDIT_WHITELIST= gql`
  mutation($whitelist: WhitelistInput) 
  {
    UpdateWhitelist(whitelist: $whitelist)
  }
`;

const REMOVE_WHITELIST= gql`
  mutation($whitelist_ssid_list_id: String!) 
  {
    RemoveWhitelist(whitelist_ssid_list_id: $whitelist_ssid_list_id)
  }
`;

const CREATE_WHITELIST= gql`
  mutation($whitelist: WhitelistInput) 
  {
    CreateWhitelist(whitelist: $whitelist)
  }
`;

const ASSIGN = gql`
  mutation($whitelist: WhitelistInput) 
  {
    WhitelistAssignDevices(whitelist: $whitelist)
  }
`;

const UNASSIGN = gql`
  mutation($whitelist: WhitelistInput) 
  {
    WhitelistUnassignDevices(whitelist: $whitelist)
  }
`;

export function useWhitelistMutations() {

  const [edit] = useMutation(EDIT_WHITELIST, {
    refetchQueries: ['getWhitelists', 'getSSIDs', 'getDevices']
  });

  const [remove] = useMutation(REMOVE_WHITELIST, {
    refetchQueries: ['getWhitelists','getSSIDs', 'getDevices']
  });

  const [create] = useMutation(CREATE_WHITELIST, {
    refetchQueries: ['getWhitelists','getSSIDs']
  });

  const [assign] = useMutation(ASSIGN, {
    refetchQueries: ['getWhitelists','getSSIDs', 'getDevices']
  });

  const [unassign] = useMutation(UNASSIGN, {
    refetchQueries: ['getWhitelists','getSSIDs', 'getDevices']
  });
  
  return { edit, remove, create, assign, unassign };
}
