import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Modal, Button, Row, Col} from 'react-bootstrap';

export const WhitelistConfirmModal = (props) => {
  const {onChange} = props;
  const [showConfirm, setShowConfirm] = useState(false);
  const handleShow = () => setShowConfirm(true);
  const handleClose = () => setShowConfirm(false);

  let modalConfirmMessage = "Are you sure you want to apply this updated SSID Whitelist to ALL assigned SafeCases in the system?"
  
  const handleConfirm = () => {
    onChange();
    if(showConfirm) {
      handleClose();
    }
  };

  return (
    <>
      <Button data-testid="" onClick={handleShow} className="mb-3">Submit</Button>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={showConfirm}
        onHide={handleClose}
        animation={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm SSID Whitelist</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row className="align-items-center">
            <Col className="align-self-center">
              <b className="text-center">{modalConfirmMessage}</b>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-center">
            <Button data-testid="cancel-modal" onClick={handleClose} variant="secondary">Cancel</Button> 
            <Button data-testid="confirm-modal" onClick={handleConfirm} className="ms-2">Confirm</Button> 
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

WhitelistConfirmModal.propTypes = {
  onChange: PropTypes.func
}

export default WhitelistConfirmModal;
