import {useEffect, useState} from 'react';
import Table from '../table';
import ActionBar from '../actionBar';
import {useQuery, gql} from "@apollo/client";
import {useRecoilValue} from 'recoil';
import {pageSizeRecoil, showDeleted} from '../recoil';
import {dateFormatter} from '../dateFormatter';
import CreateWhitelistModal from './createWhitelist';
import EditWhitelist from './editWhitelist';
import Portal from '../portal';
import {Link} from 'react-router-dom';
import {useSearch, useSort} from '../../lib/useQueryModifiers';

export const Whitelist = props => {
  const [search, setSearch] = useSearch();
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useState(1);
  const [totalRecordsState, setTotalRecordsState] = useState(0);
  const showingDeleted = useRecoilValue(showDeleted);
  const pageSize = useRecoilValue(pageSizeRecoil);
  const {data, loading} = useQuery(WHITELISTS,
    {
      variables: {
        filters: {
          combineAnd: [
            ...search,
            ...sort,
            {
              field: "options.show_deleted",
              value: `${showingDeleted}`,
            },
            {
              field: "with.devices", value: "1"
            },
            {
              field: "options.page.num",
              value: `${pageNum}`
            },
            {
              field: "options.page.len",
              value: `${pageSize}`
            }
          ]
        }
      }
    }
  );

  let whitelists = data?.Whitelists?.result || [];

  let totalQueried = data?.Whitelists?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);

  useEffect(() => {
    //attempt callback to set pageNum back to 1
    //races useQuery
    return setPageNum(1)
  }, [search]);

  const assignmentsFormatter = (cell, row) => {
    if(cell?.length) {
      return (  
        <Link style={{display: 'inline', padding: '1rem', margin: '-1rem'}} to={'/ssid-whitelist/assignments'} state={{whitelist_ssid_list_id: row.id, title: row.name}} onClick={e => e.stopPropagation()}>
          {cell?.length}
        </Link>
      )
    } else {
      return '0'
    }
  }

  const columns = [
    {
      dataField: 'id',
      hidden: true
    },
    {
      dataField: 'name',
      text: 'Whitelist Name'
    },
    {
      dataField: 'devices',
      text: 'Assignments',
      formatter: assignmentsFormatter
    },
    {
      dataField: 'modified_at',
      text: 'Last Updated (UTC)',
      sort: true,
      formatter: dateFormatter
    }
  ];
  
  return (
    <>
      <Portal title="SSID Whitelist">
        <EditWhitelist title="Edit SSID Whitelist" type="wifi" />
      </Portal>
      <ActionBar showHidden={false} showSearchInput={false} title={"SSID Whitelists"}>
        <CreateWhitelistModal />
      </ActionBar>
      <Table
        testId="ssid-whitelist-table"
        columns={columns}
        data={whitelists}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        totalRecords={totalRecordsState}
        setSort={setSort}
      />
    </>
  );
};

const WHITELISTS = gql`
  query getWhitelists($filters: GenericFilters!) {
    Whitelists(filters: $filters) {
      result {
        id
        name
        created_at
        modified_at
        devices {
          id
        }
      }
      metadata {
        page {
          num
          len
          total
          inPage
          records
        }
      }
    }
  }
`;

export default Whitelist;
