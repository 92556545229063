import {useEffect, useState} from "react";
import {Link} from 'react-router-dom';
import {Row, Col, Container} from 'react-bootstrap';
import {ReactComponent as Logo} from '../../assets/portal_logo.svg';
import {getClient} from "./client";
import {getEnv} from '../../getEnv';
import {resetAuthStore, resetSessionStore, getRefreshToken, getIDToken, getSessionStore} from './sessionStore';
import {logSignoutManually} from './siemReporterMutations';

export const endKeycloakSession = async (reason) => {
  let refresh_token = getRefreshToken();
  let id_token = getIDToken();
  let client = await getClient();
  let env = getEnv();
  
  const response = await logSignoutManually(reason);
  
  if(response?.data) {
    let url = client.endSessionUrl({
      id_token_hint: id_token,
      refresh_token: refresh_token,
      post_logout_redirect_uri: env.cdn_url + '/signout'
    });
    window.location.replace(url);
  } else {
    //Clear out any remaining token info
    window.location.replace('/signout');
    resetAuthStore();
    resetSessionStore();
  }
  
};

export const SignOut = () => {
  const [exitState, setExitState] = useState('signout');
  let {sessionStatus} = getSessionStore();
  let checked = false;
  
  useEffect(() => {
    if(!checked) {
      switch(sessionStatus) {
        case 'duplicate':
          setExitState('duplicate');
          break;
        case 'timeout':
          setExitState('timeout');
          break;
        default:
          setExitState('signout');
          break;
      }
      resetAuthStore();
      //resetSessionStore();
      checked = true;
    }    
  }, [checked]);

  return (
    <Container fluid className="signin" data-testid="signout">
      <Row>
        <Col>
          <Row className="py-5">
            <Col className="text-center">
              <div className="login-logo mx-auto">
                <Logo />
              </div>

              <div className="login-links">
                {(exitState === 'duplicate') &&
                  <>
                    <p>Please wait, you are being signed out.</p>
                    <p>Reason: Detected an already active session for this user.
                     Only one session per user is permitted. 
                     Sign out from all other locations before attempting again to sign in here.
                    </p> 
                  </>
                }

                {(exitState === 'timeout') &&
                  <>
                    <p>Your session has timed out.</p>
                    <p>
                      <Link data-testid="signin-again" to={'/signin'}>Sign In</Link> to continue.
                    </p>
                  </>
                }
                
                {(exitState !== 'timeout' && exitState !== 'duplicate') &&  
                  <>
                    <p>You are signed out.</p>
                    <p>
                      <Link data-testid="signin-again" to={'/signin'}>Click here</Link> to sign in.
                    </p>
                  </>
                }
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
