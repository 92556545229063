import Tooltip from '../tooltip';

export const hdmFormatter = (cell, row) => {
  return (
    <Tooltip id={`hdmid-${row.id}`} content={cell}>
      <div className="text-truncate d-block" style={{width: '80px'}}>{cell}</div>
    </Tooltip>
  );
}

