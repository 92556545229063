import {refreshToken} from './client';
import {useEffect} from 'react';
import {decodeJwt} from 'jose';
import {endKeycloakSession} from './signOut';
import {setAuthStore} from './sessionStore';
import {useReportingMutations} from './siemReporterMutations';

export const RefreshToken = () => {
  const {log} = useReportingMutations();
  let refresh_token_time = 240000;

  useEffect(() => {
    
    const interval = setInterval(async () => {
      
      const fetchData = async () => {
        const data = await refreshToken();
        return data;
      }

      try {
        const response = await fetchData();
        if(response?.access_token) {
          let decoded = decodeJwt(response.access_token);

          let newD = new Date(decoded?.exp * 1000);
          const iso = newD.toISOString().split('.')[0]+"Z";
          
          //Log the event
          let event = {
            name: "portalTokenRefresh",
            field: "tokenExpiryTime",
            value: `${iso}`
          };
          
          log({variables: {event: event}});

          setAuthStore(Object.assign({}, response, {claims: decoded}));
        } else {
          endKeycloakSession("access token missing from refresh");
        }
      } catch (error) {
        endKeycloakSession("manual token refresh failure");
      }      
    }, refresh_token_time); //4 min for a 5 min auth token
    
    return () => clearInterval(interval);
  }, []);

  return null;
}

export default RefreshToken;
