import {createRoot} from 'react-dom/client';
import App from './App';
import {RecoilRoot} from 'recoil';
import AuthedApolloProvider from './components/auth/authedApolloProvider';
import FeatureContextProvider from './lib/context/featureContext';

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
  <RecoilRoot>
    <AuthedApolloProvider>
      <FeatureContextProvider>
        <App />  
      </FeatureContextProvider>
    </AuthedApolloProvider>
  </RecoilRoot>
);
