import {useState, useEffect} from 'react';
import {useDebounce} from 'use-debounce';
import {useQuery,gql} from "@apollo/client";
import Table from '../table';
import ActionBar from '../actionBar';
import {dateFormatter} from '../dateFormatter';
import {useRecoilValue} from 'recoil';
import { pageSizeRecoil} from '../recoil';
import {useSort, useSearch} from '../../lib/useQueryModifiers';
import {hdmFormatter} from './columnFormatters';

export const SHKeys = props => {
  const [sort, setSort] = useSort();
  const [pageNum, setPageNum] = useDebounce(1, 1000);
  const [search, setSearch] = useSearch();
  const [totalRecordsState, setTotalRecordsState] = useState(10);
  const pageSize = useRecoilValue(pageSizeRecoil);
  
  const {data, loading} = useQuery(SHKEYS,
    {
    variables: {
      filters: {
        combineAnd: [
          ...search,
          ...sort,
          {
            field: "with.transport_device",
            value: "1"
          },
          {
            field: "search.fields",
            value: `sh_key`,
          },
          {
            field: "search.fields",
            value: `status`,
          },
          {
            field: "search.fields",
            value: `transport_device.serial_number`,
          },
          {
            field: "search.fields",
            value: `ss_hdm_id`,
          },
          {
            field: "search.fields",
            value: `batch_id`,
          },
          {
            field: "options.page.num",
            value: `${pageNum}`
          },
          {
            field: "options.page.len",
            value: `${pageSize}`
          }
        ]
      }
    }
  });
  const shKeys = data?.SHKeys?.result ?? [];

  let totalQueried = data?.SHKeys?.metadata?.page?.records ?? totalRecordsState;

  useEffect(()=> {
    setTotalRecordsState(totalQueried);
  }, [totalQueried]);
  
  const columns = [
    {
      dataField: 'id',
      hidden: true,
    },
    {
      dataField: 'sh_key',
      text: 'HDM Key',
    },
    {
      dataField: 'status',
      text: 'Status',
      formatter: (cell, row) => {
        let subStates = {
          N: {
            stage: 'Unassigned'
          },
          S: {
            stage: 'Assigned'
          },
          J: {
            stage: 'Rejected'
          },
          A: {
            stage: 'Accepted'
          },
          X: {
            stage: 'Activation Failed'
          },
          F: {
            stage: 'Validation Failed'
          },
          C: {
            stage: 'Consumed'
          },
          R: {
            stage: 'Pending Reset'
          }
        };
        let state = subStates[cell];

        return state.stage;
      }
    },
    {
      dataField: 'transport_device.serial_number',
      text: 'SAFECASE SERIAL NO.'
    },
    {
      dataField: 'ss_hdm_id',
      text: 'HDM ID',
      formatter: hdmFormatter
    },
    {
      dataField: 'batch_id',
      text: 'Purchase ID'
    },
    {
      dataField: 'user_accepted_at',
      text: 'Accepted Date (UTC)',
      formatter: dateFormatter
    },
    {
      dataField: 'consumed_at',
      text: 'Consumed Date (UTC)',
      formatter: dateFormatter
    }
  ];

  return (
    <>
      <ActionBar showHidden={false} searchChange={setSearch} title="HDM Keys" />
      
      <Table
        columns={columns}
        data={shKeys}
        loading={loading}
        pageSize={pageSize}
        pageNum={pageNum}
        setPageNum={setPageNum}
        setSort={setSort}
        totalRecords={totalRecordsState}
        disableRowClick={true}
      />
    </>
  );
};

const SHKEYS = gql`  
query getSHKeys ($filters: GenericFilters) {
  SHKeys (filters: $filters) {
    result {
      id
      sh_key
      account_id
      status
      needs_update
      transport_device_id
      ss_hdm_id
      batch_id
      user_accepted_at
      consumed_at
      created_at
      modified_at
      error_code
      transport_device {
        id
        serial_number
        user {
          username
        }
      }
    }
    metadata {
      page {
        num
        len
        total
        inPage
        records
      }
    }
  }
}
`;

export default SHKeys;
