import {createContext, useContext, useState} from 'react';
import PropTypes from 'prop-types';
import {getEnv} from '../../getEnv';

const FeatureContext = createContext();

export const FeatureContextProvider = (props) => {
  const {feature_whitelist, feature_subscriptions, feature_hdm_keys} = getEnv();
  const [features,] = useState({
    whitelist: feature_whitelist === 'true',
    subscriptions: feature_subscriptions === 'true',
    hdm_keys: feature_hdm_keys === 'true'});

  const {children} = props;

  return (
    <FeatureContext.Provider
      value={{
        feature_subscriptions: features?.subscriptions,
        feature_whitelist: features?.whitelist,
        feature_hdm_keys: features?.hdm_keys
      }}
    >{children}
    </FeatureContext.Provider>
  );
};

FeatureContextProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default FeatureContextProvider;

export const useFeatureContext = () => {
  return useContext(FeatureContext);
};
