//Replace environment specific config over the react app env config
function assignDefined(configEnv = {}, processEnv) {
  let configuredEnv = {};

  if(process.env.NODE_ENV === 'development') {
    configuredEnv = processEnv;
  } else {
    Object.keys(configEnv).forEach(function(key) {
      configEnv[key] = configEnv[key] ?? processEnv[key];
    })
    configuredEnv = configEnv;  
  }

  return configuredEnv;
  
};

// for local dev, we'll use process.env values
// but for other environments we'll use window.env
// (which is set from config.js file mounted to /app/public/config.js)
export const getEnv = () => assignDefined(window.env, {
  keycloak_host: process.env.REACT_APP_KEYCLOAK_HOST,
  keycloak_realm: process.env.REACT_APP_KEYCLOAK_REALM,
  keycloak_idp_hint: process.env.REACT_APP_KEYCLOAK_IDP_HINT,
  oauth_discovery_url: process.env.REACT_APP_OAUTH_DISCOVERY_URL,
  oauth_client_id: process.env.REACT_APP_OAUTH_CLIENT_ID,
  oauth_redirect_uri: process.env.REACT_APP_REDIRECT_URL,
  cdn_url: process.env.REACT_APP_CDN_URL,
  graphql_endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT,
  refresh_token_time: process.env.REACT_APP_REFRESH_TOKEN_TIME,
  idle_timeout: process.env.REACT_APP_IDLE_TIMEOUT,
  agreement_version: process.env.REACT_APP_AGREEMENT_VERSION,
  feature_whitelist: process.env.REACT_APP_FEATURE_WHITELIST,
  feature_subscriptions: process.env.REACT_APP_FEATURE_SUBSCRIPTIONS,
  feature_hdm_keys: process.env.REACT_APP_FEATURE_HDM_KEYS
});

