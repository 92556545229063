import {Form, Row, Col } from 'react-bootstrap';
import { usePortalContext } from '../../lib/context/portalContext';
import moment from 'moment';

const AccountDetails = (props) => {
  const {rowData} = usePortalContext();
  
  const status = {
    P: "Active",
    N: "Pending",
    C: "Completed",
    X: "Cancelled" 
  }
  
  return (
    <div className="offcanvas-form">
      <Form className="mb-3" >
        <Row>
          <Col>
            <Form.Group className="mb-3">
              <Form.Label>Subscription Type</Form.Label>
              <Form.Control defaultValue={rowData?.type === 'AS' ? 'Fulcrum' : rowData?.type} readOnly={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Start Date</Form.Label>
              <Form.Control defaultValue={moment.unix(rowData?.start_time).utc().format("MMM DD, YYYY HH:mm:ss")} readOnly={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Expiration</Form.Label>
              <Form.Control defaultValue={moment.unix(rowData?.end_time).utc().format("MMM DD, YYYY HH:mm:ss")} readOnly={true} />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Status</Form.Label>
              <Form.Control defaultValue={status[rowData?.status]} readOnly={true} />
            </Form.Group>
          </Col>
        </Row>
      </Form>            
    </div>
  );
}

export default AccountDetails;
