import {Row, Col, Form, Badge } from 'react-bootstrap';
import { usePortalContext } from '../../lib/context/portalContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheck, faXmark} from '@fortawesome/pro-solid-svg-icons';
import dateFormat from '../../lib/dateFormat';
import moment from 'moment';
import variables from '../../styles/_variables.module.scss';

const renderStates = (states, hdmPossible) => {
  
  if(!hdmPossible) {
    states.splice(-1);
  }

  return states?.map((state) => (
    <Row key={state.name}>
      <Col className="rules-pills">
        <Form.Group className="mb-3">
          <Form.Label>{state.name}</Form.Label>
          <div className="d-flex">
            <Badge pill bg="none">
              <FontAwesomeIcon size="lg" icon={state.icon} color={state.iconColor} />
              <span style={{color: state.textColor}}> {state.text}</span>
            </Badge>
          </div>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group className="mb-3">
          <Form.Label>Expiration Date</Form.Label>
          <div className="d-block">
            <span style={{color: state.dateColor}}>
              {
                state.date != null && dateFormat(state.date)
              }
            </span>
          </div>
        </Form.Group>
      </Col>
    </Row>
  ))
}

const SubscriptionDetails = props => {
  const {rowData} = usePortalContext();
  
  //Should we show the HDM Subscription (bool)
  const hdmPossible = rowData?.product_type?.greyhound_family || rowData?.product_type?.samsung_family;

  const config = rowData?.device_configuration ?? {};
  const unixNow = moment().unix();

  //Feature active by expiry date
  const fulcrumActive = config?.is_anti_surveillance_enabled_expiry >= unixNow;
  const hdmActive = config?.is_hdm_enabled_expiry >= unixNow;
    
  //Feature active, but past end date - in grace period until expiration
  const fulcrumGrace = unixNow >= config?.is_anti_surveillance_enabled_end_date && fulcrumActive;
  const hdmGrace = unixNow >= config?.is_hdm_enabled_end_date && hdmActive;

  const states = [
    {
      name: 'Fulcrum',
      icon: (fulcrumActive ? faCheck : faXmark),
      iconColor: (!fulcrumActive || fulcrumGrace) ? variables?.privoroRed : variables?.privoroGreen,
      text: !fulcrumActive ? 'Inactive' : 'Active',
      textColor: fulcrumGrace && fulcrumActive ? variables?.privoroRed : 'black',
      date: fulcrumActive ? config?.is_anti_surveillance_enabled_end_date : null,
      dateColor: fulcrumGrace && fulcrumActive ? variables?.privoroRed : 'black'
    },
    {
      name: 'Privoro HDM',
      icon: (hdmActive ? faCheck : faXmark),
      iconColor: (!hdmActive || hdmGrace) ? variables?.privoroRed : variables?.privoroGreen,
      text: !hdmActive ? 'Inactive' : 'Active',
      textColor: hdmGrace && hdmActive ? variables?.privoroRed : 'black',
      date: hdmActive ? config?.is_hdm_enabled_end_date : null,
      dateColor: hdmGrace && hdmActive ? variables?.privoroRed : 'black'
    }
  ];

  return (
    <div className="offcanvas-form" data-testid="offcanvas-safecase">
      {renderStates(states, hdmPossible)}
    </div>
  )
}

export default SubscriptionDetails;
