import {useState} from 'react';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {Row, Col} from 'react-bootstrap';
import withAlerts from '../../lib/withAlerts';
import {FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faXmark, faPlus} from '@fortawesome/pro-light-svg-icons';
import {compose} from 'redux';
import Modal from 'react-bootstrap/Modal';
import { useForm, useFieldArray, Controller, useWatch } from "react-hook-form";
import { useQuery, useLazyQuery, gql } from "@apollo/client";
import WhitelistConfirmModal from './confirmWhitelistModal';
import {useWhitelistMutations} from './whitelistMutations';
import cx from 'classnames';

export const CreateWhitelistModal = (props) => {
  const [show, setShow] = useState(false);
  const { register, control, handleSubmit, reset, setFocus, watch, formState: {errors} } = useForm({
    defaultValues: {
      ssids: [{ ssid: "" }]
    }
  });
  const {
    fields,
    append,
    remove
  } = useFieldArray({
    control,
    name: "ssids"
  });
  const {create} = useWhitelistMutations();
  let {success, error} = props.alerts;
  const [duplicateName, setDuplicateName] = useState(false);
  const [getWhitelistNames] = useLazyQuery(WHITELISTS);
  
  const handleShow = () => {
    reset();
    setShow(true);
  }
  const handleClose = () => setShow(false);

  const nameCheck = async (name) => {
    
    if(name !== '') {
      let check = await getWhitelistNames({
        variables: {
          filters: {
            field: "name",
            value: name
          }
        }
      })

      if(check?.data?.Whitelists) {
        setDuplicateName(true);
        setFocus('name')
        return;
      } else {
        setDuplicateName(false);  
      }
    }
  }


  
  const handleWhitelist = async (formData) => {
    let name = formData?.name || "";

    let filtered = JSON.stringify(formData?.ssids?.filter(({ssid}) => {
        return ssid != null && ssid != "";
    }).map((el) => el.ssid));

    const whitelistInput = {
      name: name,
      SSIDs: filtered
    };

    const createWhitelist = await create({variables: {whitelist: whitelistInput}});
    if(createWhitelist.errors) {

      console.error(JSON.stringify(createWhitelist?.errors));
      error({msg: "Whitelist creation failed."});

    } else {
      success({msg: `Whitelist created.`});
      reset();
      handleClose();
    }
  }

  const max8 = watch("ssids").length === 8;

  return (
    <>
      <Button data-testid="" className="ms-3" onClick={handleShow}>Create SSID Whitelist</Button>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        show={show}
        onHide={handleClose}
        animation={false}
        className="modal-white"
      >
        <Form className="mb-3" onSubmit={handleSubmit(handleWhitelist)}>
          <Modal.Header closeButton>
            <Modal.Title>Create SSID Whitelist</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="modal-form-white">
              <Row>
                <Col>
                  <Form.Group className="mb-5">
                    <Form.Label>Whitelist Name</Form.Label>
                    <Form.Control
                    {...register("name", {
                      onBlur: (e) => nameCheck(e.target.value),
                      required: true
                    })}
                    type="text"
                    placeholder="Name"
                    className={cx({'error-input': duplicateName || errors.name })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Label>Entries <small className="privoro-gray"> (max 8)</small></Form.Label>
                  {fields.map((item, i) => {
                    return (
                      <Form.Group className="ssid-input mb-3" key={`whitelist_${i}`}>
                        <Form.Control
                          {...register(`ssids.${i}.ssid`)}
                          type="text"
                          placeholder="SSID..."
                          control={control}
                          />
                      </Form.Group>
                    )
                  })}
                </Col>
              </Row>
              {!max8 && <Row className="mb-3">
                <Col>
                  <span
                    className="privoro-gray"
                    onClick={() => {append({ssid: ""})}}
                    onKeyDown={() => {append({ssid: ""})}}>
                      <FontAwesomeIcon icon={faPlus} size="sm" /> <span className="link-span">Add Row</span></span>
                </Col>
              </Row>}
            </div>
            
            {errors.name && errors.name.type === "required" &&
              <div className="d-grid gap-2">
                <strong className="privoro-black">SSID Whitelist name is required.<br/></strong>
              </div>
            }

            {duplicateName &&
              <div className="d-grid gap-2">
                <strong className="privoro-black">SSID Whitelist name already exists.<br/></strong>
              </div>
            }

          </Modal.Body>
          <Modal.Footer>
            <div className="d-flex justify-content-end">
              <Button onClick={handleClose} variant="secondary">Cancel</Button> 
              <Button type="submit" className="ms-2" disabled={duplicateName || errors.name}>Submit</Button> 
            </div>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
}

CreateWhitelistModal.propTypes = {
  alerts: PropTypes.shape({
    error: PropTypes.func,
    success: PropTypes.func
  })
}

const WHITELISTS = gql`
  query getWhitelists($filters: GenericFilters!) {
    Whitelists(filters: $filters) {
      result {
        name
      }
    }
  }
`;

export default compose(
  withAlerts,
)(CreateWhitelistModal);
